import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { Layout } from './layout/Layout';
import { StartView } from './features/start/StartView';
import { useSession } from './hooks/useSession';
import { CustomersListView } from './features/customers/CustomersListView';
import { FoundationsListView } from './features/foundations/FoundationsListView';
import { LoginView } from './features/start/LoginView';
import { CustomerView } from './features/customers/CustomerView';
import { FoundationView } from './features/foundations/FoundationView';
import { PageNotFound } from './layout/PageNotFound';
import { CreateCustomerView } from './features/customers/CreateCustomerView';

export const Router: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const session = useSession();
  const { selectedOrganisation, sessionFetched } = session;

  if (isAuthenticated) {
    //   if (!sessionFetched) {
    //     return null;

    return (
      <Routes>
        {/* <Route path="/logout" element={<LogoutView />} /> */}
        <Route path="/" element={<Layout loadNavBar={true} />}>
          <Route path="" element={<StartView />} />
          <Route path="customers" element={<CustomersListView />} />
          <Route path="customers/new" element={<CreateCustomerView />} />
          <Route path="customers/new/:foundationId" element={<CreateCustomerView />} />
          <Route path="customers/:customerId" element={<CustomerView />} />
          <Route path="foundations" element={<FoundationsListView />} />
          <Route path="foundations/:foundationId" element={<FoundationView />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="" element={<LoginView />} />
      </Routes>
    );
  }
};
