import { Grid, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';

export const StartView: React.FC = () => {
  const [isFetching, setIsFetching] = useState(false);
  const api = useApi();
  const [dashboard, setDashboard] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);

      const result = await api.getDashboard();
      setDashboard(result);

      setIsFetching(false);
    };

    fetchData();
  }, []);

  if (!dashboard) return <></>;

  return (
    <Grid p={'xl'} w={'100%'}>
      <Grid.Col sm={4}>
        <Text size={'sm'} weight={'bold'}>
          Antal kunder
        </Text>
        <Text size={'xl'}>{dashboard.numberOfCustomers}</Text>
      </Grid.Col>
      <Grid.Col sm={4}>
        <Text size={'sm'} weight={'bold'}>
          Antal sökanden
        </Text>
        <Text size={'xl'}>{dashboard.numberOfApplicants}</Text>
      </Grid.Col>
      <Grid.Col sm={4}>
        <Text size={'sm'} weight={'bold'}>
          Antal ansökningar
        </Text>
        <Text size={'xl'}>{dashboard.numberOfApplications}</Text>
      </Grid.Col>
    </Grid>
  );
};
