import React from 'react';
import { createStyles, Header, Container, Anchor, Group, Burger } from '@mantine/core';
import { Link } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { useSessionStorage } from '@mantine/hooks';
import { OrganisationAndProfileMenu } from './OrganisationAndProfileMenu';
import { useState } from 'react';
import { usePermissions } from '../hooks/usePermissions';

export const HEADER_HEIGHT = 64;
export const STICKY_HEIGHT = 105;

const useStyles = createStyles((theme) => ({
  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    margin: 8,
    padding: 16,
    border: '1px solid lightgray',
    backgroundColor: theme.colors[theme.primaryColor][8],

    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },

  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  burger: {
    [theme.fn.largerThan('lg')]: {
      display: 'none',
    },
  },

  links: {
    height: HEADER_HEIGHT,
    display: 'flex',
    paddingRight: '16px',

    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  mainLinks: {
    flex: 1,
    marginRight: -theme.spacing.sm,
  },

  mainLink: {
    textTransform: 'uppercase',
    fontSize: 13,
    color: theme.colors.dark[0],
    padding: `7px ${theme.spacing.sm}px`,
    fontWeight: 700,
    borderBottom: '2px solid transparent',
    transition: 'border-color 100ms ease, color 100ms ease',
    letterSpacing: '1.5px',

    '&:hover': {
      color: theme.white,
      textDecoration: 'none',
    },
  },

  mainLinkActive: {
    color: theme.white,
    borderBottomColor: theme.colors[theme.primaryColor][3],
  },

  header: {
    border: 0,
    background: theme.colors[theme.primaryColor][8],
    position: 'static',
  },

  organisationSelector: {
    display: 'flex',
    flexDirection: 'row',
  },

  logo: {
    content: 'url("/grantme_light_transparent.png")',
    maxWidth: 192,

    [theme.fn.smallerThan('lg')]: {
      content: 'url("/logo512_light.png")',
      maxHeight: HEADER_HEIGHT - 32,
    },
  },
}));

interface MenuHeaderProps {
  simpleMode?: boolean;
}

export const MenuHeader: React.FC<MenuHeaderProps> = ({ simpleMode }) => {
  const { classes } = useStyles();
  const [openNavbar, setOpenNavBar] = useSessionStorage({
    key: 'open-navbar',
    defaultValue: false,
  });

  return (
    <Header className={classes.header} height={HEADER_HEIGHT} fixed={false}>
      <Container w={'100%'} maw={'unset'} className={classes.inner}>
        <Group position={'apart'} style={{ width: '100%' }}>
          <Group>
            {!simpleMode && (
              <AuthenticatedTemplate>
                <Burger
                  opened={openNavbar}
                  onClick={() => setOpenNavBar(!openNavbar)}
                  className={classes.burger}
                  size={'md'}
                  color={'#FFFFFF'}
                />
              </AuthenticatedTemplate>
            )}
            <Anchor component={Link} to="/">
              <img className={classes.logo}></img>
            </Anchor>
          </Group>
          {!simpleMode && (
            <Group>
              <AuthenticatedTemplate>
                <OrganisationAndProfileMenu />
              </AuthenticatedTemplate>
            </Group>
          )}
        </Group>
      </Container>
    </Header>
  );
};
