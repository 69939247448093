import { PagedResult } from './PagedResult';
import { isPagedResult } from './IsPagedResult';

export const getGridData = <T>(data: PagedResult<T> | T[]): T[] => {
  if (isPagedResult(data)) {
    return data.data;
  }

  return data;
};
