import { Button, Container, Group, Stack, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { CustomersListViewColumns } from './CustomersListViewColumns';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { Page } from '../../layout/Page';

export const CustomersListView: React.FC = () => {
  const [customers, setCustomers] = useState<any[]>();
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const api = useApi();

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);

      const result = await api.getCustomers();
      setCustomers(result);

      setIsFetching(false);
    };

    fetchData();
  }, []);

  return (
    <Page>
      <Page.Header>
        <Group position="apart">
          <Title order={3}>Kundregister</Title>
          <Button onClick={() => navigate('/customers/new/')}>Skapa ny kund</Button>
        </Group>
      </Page.Header>
      <Page.Body>
        <LiitGrid
          data={customers}
          columnInfo={CustomersListViewColumns()}
          onRowClick={(id: string) => {
            navigate(`/customers/${id}`);
          }}
          highligthOnHoover
          fetchingData={isFetching}
        />
      </Page.Body>
    </Page>
  );
};
