import { Stack, Title, TextInput, Grid, Text, Container, Button, Group } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconSearch } from '@tabler/icons';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { FoundationsListViewColumns } from './FoundationsListViewColumns';
import { useDebouncedState } from '@mantine/hooks';
import { ContentContainer } from '../../layout/ContentContainer';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { ViewContainer } from '../../layout/ViewContainer';

export const FoundationView: React.FC = () => {
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const api = useApi();
  const { foundationId } = useParams();
  const [foundation, setFoundation] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      if (foundationId) {
        setIsFetching(true);

        const result = await api.getFoundation(foundationId);
        setFoundation(result);

        setIsFetching(false);
      }
    };

    fetchData();
  }, [foundationId]);

  const getFoundationType = (type) => {
    switch (type) {
      case 1:
        return 'Pensionsstiftelse';
      case 2:
        return 'Personalstiftelse';
      case 3:
        return 'Insamlingsstiftelse';
      case 4:
        return 'Kollektivavtalsstiftelse';
      case 5:
        return 'Näringsdrivande stiftelse';
      case 6:
        return 'Annan stiftelse';
      default:
        return 'Uppgift saknas';
    }

    // Stiftelse typer:
    // TYP NAMN
    // -1  Uppgift saknas
    // 1   Pensionsstiftelse
    // 2   Personalstiftelse
    // 3   Insamlingsstiftelse
    // 4   Kollektivavtalsstiftelse
    // 5   Näringsdrivande stiftelse
    // 6   Annan stiftelse
  };

  if (!foundation) return <></>;

  return (
    <GrantMeContainer>
      <Stack spacing={0}>
        <GrantMeTitleContainer>
          <Title order={3}>{foundation.name}</Title>
          <Button onClick={() => navigate('/customers/new/' + foundation.id)}>Skapa kund</Button>
        </GrantMeTitleContainer>
        <ViewContainer>
          <Grid>
            <Grid.Col span={3}>
              <b>Namn</b>
            </Grid.Col>
            <Grid.Col span={9}>{foundation.name}</Grid.Col>
            <Grid.Col span={3}>
              <b>Typ</b>
            </Grid.Col>
            <Grid.Col span={9}>{getFoundationType(foundation.type)}</Grid.Col>
            <Grid.Col span={3}>
              <b>Org.nummer</b>
            </Grid.Col>
            <Grid.Col span={9}>{foundation.organisationNumber}</Grid.Col>
            <Grid.Col span={3}>
              <b>Adress</b>
            </Grid.Col>
            <Grid.Col span={9}>
              {foundation.address}
              <br />
              {foundation.addressCo}
              <br />
              {foundation.zipCode} {foundation.city}
            </Grid.Col>
            <Grid.Col span={3}>
              <b>Län</b>
            </Grid.Col>
            <Grid.Col span={9}>{foundation.county}</Grid.Col>
            <Grid.Col span={3}>
              <b>Telefon</b>
            </Grid.Col>
            <Grid.Col span={9}>{foundation.phone}</Grid.Col>
            <Grid.Col span={3}>
              <b>Www</b>
            </Grid.Col>
            <Grid.Col span={9}>{foundation.www ? foundation.www : '-'}</Grid.Col>
            <Grid.Col span={3}>
              <b>Syfte</b>
            </Grid.Col>
            <Grid.Col span={9}>{foundation.purpose}</Grid.Col>
            <Grid.Col span={3}>
              <b>Firmateckning</b>
            </Grid.Col>
            <Grid.Col span={9}>{foundation.signature ? foundation.signature : '-'}</Grid.Col>
            {foundation.roles && (
              <>
                <Grid.Col span={3}>
                  <b>Representanter</b>
                </Grid.Col>
                <Grid.Col span={9}>
                  <Stack>
                    {foundation.roles.map((x) => (
                      <Text key={'role_' + x.id}>
                        {x.name}
                        <br />
                        {x.type}
                        <br />
                        {x.identityNumber}
                      </Text>
                    ))}
                  </Stack>
                </Grid.Col>
              </>
            )}
          </Grid>
        </ViewContainer>
      </Stack>
    </GrantMeContainer>
  );
};
