import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../AuthConfig';
import { getSettings } from '../Settings';
import { handleError } from '../utils/handleError';

class Api {
  private static baseUrl: string = getSettings().SERVER_URL;

  private instance: IPublicClientApplication;

  constructor(instance: IPublicClientApplication) {
    this.instance = instance;
  }

  public async searchFoundations(keyword: string): Promise<any[]> {
    return this.get<any[]>(`/management/foundations?keyword=${keyword}`);
  }

  public async getFoundation(id: string): Promise<any> {
    return this.get<any>(`/management/foundations/${id}`);
  }

  public async getDashboard(): Promise<any> {
    return this.get<any>('/management/dashboard');
  }

  public async getCustomers(): Promise<any[]> {
    return this.get<any[]>('/management/customers');
  }

  public async getCustomer(id: string): Promise<any> {
    return this.get<any>(`/management/customers/${id}`);
  }

  public async createCustomer(customer): Promise<any> {
    return this.post('/management/customers', customer);
  }

  private async get<T>(url: string): Promise<T> {
    const options = await this.getOptions('GET');
    const response = await fetch(`${Api.baseUrl}${url}`, options);
    if (!response.ok) {
      await handleError(response);
    }

    return response.json();
  }

  private async post(url: string, body: any): Promise<Response> {
    const options = await this.getOptions('POST', JSON.stringify(body));
    try {
      const response = await fetch(`${Api.baseUrl}${url}`, options);
      if (!response.ok) {
        await handleError(response);
      }
      return response;
    } catch (error) {
      await handleError();
      throw error;
    }
  }

  private async postUnauthenticated(url: string, body: any): Promise<Response> {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(`${Api.baseUrl}${url}`, options);
      if (!response.ok) {
        await handleError(response);
      }
      return response;
    } catch (error) {
      await handleError();
      throw error;
    }
  }

  private async put(url: string, body: any): Promise<Response> {
    const options = await this.getOptions('PUT', JSON.stringify(body));
    const response = await fetch(`${Api.baseUrl}${url}`, options);

    if (!response.ok) {
      await handleError(response);
    }
    return response;
  }

  private async delete(url: string): Promise<Response> {
    const options = await this.getOptions('DELETE');
    const response = await fetch(`${Api.baseUrl}${url}`, options);
    if (!response.ok) {
      await handleError(response);
    }
    return response;
  }

  private async getOptions(
    method: string,
    body: any | null = null,
    accept = 'application/json',
  ): Promise<any> {
    const token = await this.getToken();

    const options = {
      method,
      headers: {
        Accept: accept,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body,
    };

    return options;
  }

  private async getToken(): Promise<string> {
    // MSAL.js v2 exposes several account APIs, logic to determine which
    // account to use is the responsibility of the developer
    const account = this.instance.getAllAccounts()[0];
    const accessTokenRequest = {
      ...loginRequest,
      account,
    };

    try {
      const tokenResponse = await this.instance.acquireTokenSilent(accessTokenRequest);
      return tokenResponse.accessToken;
    } catch (error) {
      await this.instance.acquireTokenRedirect(loginRequest);
      throw error;
    }
  }
}

export const useApi = (): Api => {
  const { instance } = useMsal();
  return new Api(instance);
};
