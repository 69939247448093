import { useState } from 'react';
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  createStyles,
  Badge,
} from '@mantine/core';
import { TablerIcon, IconCalendarStats, IconChevronLeft, IconChevronRight } from '@tabler/icons';
import { isPresent } from 'utilitype';
import { useLocation } from 'react-router-dom';
import { useSession } from '../hooks/useSession';

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: 'block',

    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'nowrap',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors[theme.primaryColor][0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  subLink: {
    fontWeight: 500,
    display: 'block',
    textDecoration: 'none',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    paddingLeft: 16,
    marginLeft: 32,
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    borderLeft: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors[theme.primaryColor][2]
    }`,
    whiteSpace: 'nowrap',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors[theme.primaryColor][0], // theme.colors.gray[3],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
    opacity: 1,
  },

  mainLinkActive: {
    background:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors[theme.primaryColor][1],

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors[theme.primaryColor][1], // theme.colors.gray[3],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  chevron: {
    transition: 'transform 200ms ease',
  },
}));

interface LinksGroupProps {
  icon: TablerIcon;
  label: string;
  link?: string;
  links?: { label: string; link: string; counter?: string | undefined }[];
  linkPressed: (route: string) => void;
  expandedItem: string | undefined;
  setExpandedItem: (label: string) => void;
}

export const LinksGroup = ({
  icon: Icon,
  label,
  links,
  linkPressed,
  link,
  expandedItem,
  setExpandedItem,
}: LinksGroupProps) => {
  const { classes, theme, cx } = useStyles();
  const hasLinks = Array.isArray(links);
  const opened = expandedItem === label;
  const location = useLocation();
  const { counters, refreshCounters } = useSession();
  const ChevronIcon = theme.dir === 'ltr' ? IconChevronRight : IconChevronLeft;
  const items = (hasLinks ? links : []).map((sublink) => (
    <Text<'a'>
      key={sublink.label}
      component="a"
      className={cx(classes.subLink, {
        [classes.mainLinkActive]: sublink.link && location.pathname.startsWith(sublink.link),
      })}
      href={sublink.link}
      onClick={(event) => {
        event.preventDefault();
        if (linkPressed) {
          linkPressed(sublink.link);
        }
      }}>
      <Group position="apart">
        {sublink.label}
        {sublink.counter && counters && <Badge>{counters[sublink.counter]}</Badge>}
      </Group>
    </Text>
  ));

  return (
    <>
      <UnstyledButton
        onClick={async () => {
          if (isPresent(link)) {
            linkPressed(link);
          } else {
            if (expandedItem === label) {
              setExpandedItem('');
            } else {
              setExpandedItem(label);

              if (refreshCounters) {
                await refreshCounters();
              }
            }
          }
        }}
        className={cx(classes.control, {
          [classes.mainLinkActive]: link && location.pathname.startsWith(link),
        })}>
        <Group position="apart" spacing={0} noWrap>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon size={18} />
            <Text ml="md">{label}</Text>
          </Box>
          {hasLinks && (
            <ChevronIcon
              className={classes.chevron}
              size={14}
              stroke={1.5}
              style={{
                transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
};
