const settings: { [key: string]: { [key: string]: string } } = {
  // Production
  'https://management.grantme.se': {
    SERVER_URL: 'https://api.grantme.se',
    REDIRECT_URI: 'https://management.grantme.se',
    CLIENT_URL: 'https://apply.grantme.se',
    SCOPES: 'api://5218335e-31d8-40d8-8674-ab1cf2aef5bc/manage',
    CLIENTID: '5218335e-31d8-40d8-8674-ab1cf2aef5bc',
    AUTHORITY: 'https://login.microsoftonline.com/61214881-b65d-4860-86ad-a012e02d3e76',
    KNOWN_AUTHORITY: 'login.microsoftonline.com',
  },

  // Test
  'https://test-management.grantme.se': {
    SERVER_URL: 'https://test-api.grantme.se',
    REDIRECT_URI: 'https://test-management.grantme.se',
    CLIENT_URL: 'https://test-client.grantme.se',
    SCOPES: 'api://5218335e-31d8-40d8-8674-ab1cf2aef5bc/manage',
    CLIENTID: '5218335e-31d8-40d8-8674-ab1cf2aef5bc',
    AUTHORITY: 'https://login.microsoftonline.com/61214881-b65d-4860-86ad-a012e02d3e76',
    KNOWN_AUTHORITY: 'login.microsoftonline.com',
  },

  // Development
  'http://localhost:3003': {
    SERVER_URL: 'https://localhost:7153',
    REDIRECT_URI: 'http://localhost:3003',
    CLIENT_URL: 'http://localhost:3002',
    SCOPES: 'api://5218335e-31d8-40d8-8674-ab1cf2aef5bc/manage',
    CLIENTID: '5218335e-31d8-40d8-8674-ab1cf2aef5bc',
    AUTHORITY: 'https://login.microsoftonline.com/61214881-b65d-4860-86ad-a012e02d3e76',
    KNOWN_AUTHORITY: 'login.microsoftonline.com',
  },
};

export const getSettings = (): { [key: string]: string } => {
  const rootUrl = `${window.location.protocol}//${window.location.host}`;
  return settings[rootUrl];
};
