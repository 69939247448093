import { Group, GroupPosition, useMantineTheme } from '@mantine/core';
import React from 'react';
import { BORDER_COLOR } from '../App';
import { CONTAINER_PADDING_WIDTH } from './Layout';

interface GrantMeTitleContainerProps {
  children: React.ReactNode;
  backgroundColor?: string | undefined;
  position?: GroupPosition | undefined;
}

export const GrantMeTitleContainer: React.FC<GrantMeTitleContainerProps> = ({
  children,
  backgroundColor,
  position,
}) => {
  const theme = useMantineTheme();

  return (
    <Group
      pl={CONTAINER_PADDING_WIDTH}
      pr={CONTAINER_PADDING_WIDTH}
      noWrap
      align={'center'}
      pt={'xl'}
      pb={'xl'}
      style={{
        backgroundColor: backgroundColor ? backgroundColor : theme.white,
        borderBottom: `1px solid ${BORDER_COLOR}`,
      }}>
      {children}
    </Group>
  );
};
