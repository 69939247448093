/* eslint-disable @typescript-eslint/no-unused-vars */
import { IDisplayColumn } from '../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import React from 'react';
import { t } from 'ttag';
import { CellRenderInformation } from '../../components/LiitGrid/RenderCell';
import { Flex, Group, Stack, Text } from '@mantine/core';
import Formatter from '../../utils/formatter';

const columnStyles: React.CSSProperties = {
  width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};

export const CustomersListViewColumns = (): Array<IDisplayColumn> => [
  // {
  //   displayName: 'Org.nummer',
  //   propertyName: 'organisationNumber',
  //   propertyType: PropertyType.Text,
  //   columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  // },
  {
    displayName: 'Namn',
    propertyName: 'name',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Stad',
    propertyName: 'city',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
];
