import { Grid, Group, Tabs } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { Page } from '../../layout/Page';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { IDisplayColumn } from '../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import { IconChecklist, IconMessageCircle, IconPhoto, IconSettings, IconUsers } from '@tabler/icons';

export const CustomerView: React.FC = () => {
  const [isFetching, setIsFetching] = useState(false);
  const api = useApi();
  const { customerId } = useParams();
  const [customer, setCustomer] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      if (customerId) {
        setIsFetching(true);

        const result = await api.getCustomer(customerId);
        setCustomer(result);

        setIsFetching(false);
      }
    };

    fetchData();
  }, [customerId]);

  if (!customer) return <></>;

  const listViewColumns: Array<IDisplayColumn> = [
    {
      displayName: 'Titel',
      propertyName: 'title',
      propertyType: PropertyType.Text,
    },
    {
      displayName: 'Påbörjade ansökningar',
      propertyName: 'numberOfApplicationDrafts',
      propertyType: PropertyType.Number,
    },
  ];

  return (
    <Page>
      <Page.Header title={customer.name} />
      <Page.Body>

      <Group p="xl">
        <Tabs defaultValue="settings">
          <Tabs.List>
            <Tabs.Tab value="settings" icon={<IconSettings size={14} />}>Allmänt</Tabs.Tab>
            <Tabs.Tab value="applicationperiods" icon={<IconChecklist size={14} />}>Ansökningsperioder</Tabs.Tab>
            <Tabs.Tab value="users" icon={<IconUsers size={14} />}>Användare</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="settings" pt="xs">
          <Grid p={'xl'}>
          <Grid.Col span={3}>
            <b>Slug</b>
          </Grid.Col>
          <Grid.Col span={9}>{customer.slug}</Grid.Col>
          <Grid.Col span={3}>
            <b>Tidzon</b>
          </Grid.Col>
          <Grid.Col span={9}>{customer.timeZoneId}</Grid.Col>
          <Grid.Col span={3}>
            <b>Produktvariant</b>
          </Grid.Col>
          <Grid.Col span={9}>{customer.productVariant}</Grid.Col>          
        </Grid>
          </Tabs.Panel>

          <Tabs.Panel value="applicationperiods" pt="xs">
          <LiitGrid
          data={customer.applicationPeriods}
          columnInfo={listViewColumns}
          highligthOnHoover
          fetchingData={isFetching}
        />
          </Tabs.Panel>

          <Tabs.Panel value="users" pt="xs">
            ...
          </Tabs.Panel>
        </Tabs>
      </Group>



      </Page.Body>
    </Page>
  );
};
