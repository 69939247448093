import { createStyles, Flex, keyframes, Navbar } from '@mantine/core';
import { useMediaQuery, useSessionStorage } from '@mantine/hooks';
import { IconSearch, IconUsers } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'ttag';
import { Permission } from '../models/Permission';
import { LinksGroup } from './NavbarLinksGroup';
import { navbarStyles } from './NavbarStyles';

interface MenuItemData {
  label: string;
  permission: Permission;
  icon?: React.ReactNode;
  items?: MenuItemData[];
}

const NAV_WIDTH = 240;

export const animate = keyframes({
  '0%': {
    opacity: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  '99%': {
    opacity: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  '100%': {
    opacity: 0,
    maxHeight: 0,
    paddingLeft: 0,
    paddingRight: 0,
    overflow: 'hidden',
  },
});

const animateTextStyles = createStyles((theme) => ({
  textAnimation: {
    textAlign: 'center',
    padding: theme.spacing.xl,
    animation: `${animate}`,
    animationDirection: 'alternate',
    animationDuration: '0.2s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  },
}));

export const GrantMeNavbar: React.FC = () => {
  const { classes, theme, cx } = navbarStyles();
  const { classes: animateClasses } = animateTextStyles();
  const smallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
  const navigate = useNavigate();
  const [expandedItem, setExpandedItem] = useState<string | undefined>(undefined);
  const [openNavbar, setOpenNavBar] = useSessionStorage({
    key: 'open-navbar',
    defaultValue: false,
  });

  const menuItems = [
    {
      label: 'Kundregister',
      icon: IconUsers,
      link: 'customers',
    },
    {
      label: 'Stiftelsesök',
      icon: IconSearch,
      link: 'foundations',
    },
  ];

  useEffect(() => {
    setOpenNavBar(!smallScreen);
  }, [smallScreen]);

  const links = menuItems
    .map((item) => {
      return (
        <LinksGroup
          {...item}
          key={item.label}
          linkPressed={(route: string) => {
            if (route) {
              navigate(route);
            }
          }}
          expandedItem={expandedItem}
          setExpandedItem={setExpandedItem}
        />
      );
    })
    .filter(Boolean);

  return (
    <Navbar
      className={classes.ourNavBar}
      w={openNavbar ? NAV_WIDTH : 0}
      style={{ position: smallScreen ? 'fixed' : 'unset' }}>
      <Navbar.Section className={cx()} grow p={16} style={{ overflowX: 'hidden' }}>
        <Flex
          direction={'column'}
          className={cx(classes.linksInner, { [animateClasses.textAnimation]: !openNavbar })}>
          {links}
        </Flex>
      </Navbar.Section>
    </Navbar>
  );
};
