import {
  Container,
  DefaultProps,
  MantineNumberSize,
  Space,
  Stack,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { ForwardRefWithStaticComponents } from '@mantine/utils';
import React, { forwardRef } from 'react';
import { isPresent } from 'utilitype';
import { BORDER_COLOR } from '../App';

interface PageRootProps {
  children: React.ReactNode;
}

interface PageHeaderProps {
  title?: string;
  children?: React.ReactNode;
  noborder?: boolean;
}

interface PageFooterProps {
  children?: React.ReactNode;
  noborder?: boolean;
}

interface PageBodyProps extends DefaultProps {
  children: React.ReactNode;
}

const PageRoot: React.FC<PageRootProps> = ({ children }) => {
  return (
    <Container
      fluid
      p={0}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      }}>
      {children}
    </Container>
  );
};

const PageHeader: React.FC<PageHeaderProps> = ({ title, children, noborder }) => {
  return (
    <Stack
      p={'xl'}
      style={{
        borderBottom: noborder ? '0px' : `1px solid ${BORDER_COLOR}`,
      }}>
      {title && <Title order={3}>{title}</Title>}
      {children}
    </Stack>
  );
};

const PageBody: React.FC<PageBodyProps> = ({ children, p }) => {
  const theme = useMantineTheme();
  return (
    <Stack p={p} bg={theme.colors.gray[1]}>
      {children}
    </Stack>
  );
};

const PageFooter: React.FC<PageFooterProps> = ({ children, noborder }) => {
  return (
    <Stack
      p={'xl'}
      style={{
        borderTop: noborder ? '0px' : `1px solid ${BORDER_COLOR}`,
      }}>
      {children}
    </Stack>
  );
};

export const Page = PageRoot as typeof PageRoot & {
  Header: typeof PageHeader;
  Body: typeof PageBody;
  Footer: typeof PageFooter;
};

Page.Header = PageHeader;
Page.Body = PageBody;
Page.Footer = PageFooter;
