import {
  Stack,
  Grid,
  TextInput,
  Group,
  Button,
  Textarea,
  Select,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { useForm } from '@mantine/form';
import { Page } from '../../layout/Page';

export const CreateCustomerView: React.FC = () => {
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const api = useApi();
  const { foundationId } = useParams();
  const [foundation, setFoundation] = useState<any>();

  const form = useForm({
    initialValues: {
      name: '',
      organisationNumber: '',
      description: '',
      slug: '',
      userEmail: '',
      productVariant: '',
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (foundationId) {
        setIsFetching(true);

        const result = await api.getFoundation(foundationId);
        setFoundation(result);

        form.setFieldValue('name', result.name);
        form.setFieldValue('organisationNumber', result.organisationNumber);
        form.setFieldValue('description', result.purpose);
        form.setFieldValue('slug', result.slugSuggestion);
        form.setFieldValue('productVariant', '');

        setIsFetching(false);
      }
    };

    fetchData();
  }, [foundationId]);

  const createCustomer = async (values) => {
    const response = await api.createCustomer(
      {
        ...values,
        productVariant: Number.parseInt(form.values.productVariant),
      });
    if (response.ok) {
      navigate('/customers');
    }
  };

  return (
    <Page>
      <form onSubmit={form.onSubmit(async (values) => createCustomer(values))}>
        <Page.Header title="Skapa ny kund" />
        <Page.Body p={'xl'}>
          <Grid>
            <Grid.Col sm={6}>
              <Stack>
                <TextInput label="Namn" {...form.getInputProps('name')} />

                <TextInput label="Slug" {...form.getInputProps('slug')} />

                <TextInput
                  label="Organisationsnummer"
                  {...form.getInputProps('organisationNumber')}
                />

                <Textarea
                  autosize
                  maxRows={8}
                  label="Beskrivning"
                  {...form.getInputProps('description')}
                />

                <TextInput
                  placeholder="user@domain.com"
                  label="E-post till administratör"
                  {...form.getInputProps('userEmail')}
                />

                <Select
                      label="Produktvariant"
                      placeholder="Välj produktvariant ..."
                      {...form.getInputProps('productVariant')}
                      data={[
                        { value: '0', label: 'Large' },
                        { value: '1', label: 'Small' },
                      ]}
                    />                
              </Stack>
            </Grid.Col>
          </Grid>
        </Page.Body>
        <Page.Footer>
          <Grid>
            <Grid.Col sm={6}>
              <Group position="right" mt="md">
                <Button type="submit">Spara</Button>
              </Group>
            </Grid.Col>
          </Grid>
        </Page.Footer>
      </form>
    </Page>

  // <GrantMeContainer>
  //   <Stack spacing={0}>
  //     <GrantMeTitleContainer>
  //       <Title order={3}>Kund ...</Title>
  //     </GrantMeTitleContainer>
  //     <ViewContainer>
  //       <Tabs defaultValue="gallery">
  //         <Tabs.List>
  //           <Tabs.Tab value="gallery" icon={<IconPhoto size={14} />}>
  //             Gallery
  //           </Tabs.Tab>
  //           <Tabs.Tab value="messages" icon={<IconPhoto size={14} />}>
  //             Messages
  //           </Tabs.Tab>
  //           <Tabs.Tab value="settings" icon={<IconPhoto size={14} />}>
  //             Settings
  //           </Tabs.Tab>
  //         </Tabs.List>

  //         <Tabs.Panel value="gallery" pt="xs">
  //           <form onSubmit={form.onSubmit((values) => console.log(values))}>
  //             <TextInput
  //               withAsterisk
  //               label="Namn"
  //               placeholder="Kundens namn"
  //               {...form.getInputProps('name')}
  //             />

  //             <Group position="right" mt="md">
  //               <Button type="submit">Spara</Button>
  //             </Group>
  //           </form>
  //         </Tabs.Panel>

  //         <Tabs.Panel value="messages" pt="xs">
  //           Messages tab content
  //         </Tabs.Panel>

  //         <Tabs.Panel value="settings" pt="xs">
  //           Settings tab content
  //         </Tabs.Panel>
  //       </Tabs>
  //     </ViewContainer>
  //   </Stack>
  // </GrantMeContainer>
  );
};
