import { useMsal } from '@azure/msal-react';
import {
  Menu,
  Group,
  Stack,
  Space,
  Text,
  useMantineTheme,
  createStyles,
  Avatar,
} from '@mantine/core';
import { IconSettings, IconUser, IconLogout, IconChevronDown } from '@tabler/icons';
import React from 'react';
import { t } from 'ttag';

const useStyles = createStyles((theme) => ({
  menuItemWrapper: {
    //backgroundColor: theme.colors.blue[8],
    padding: '8px 8px',
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors[theme.primaryColor][7],
    },
  },

  truncate: {
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      width: '140px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

export const OrganisationAndProfileMenu: React.FC = () => {
  const theme = useMantineTheme();

  const { instance } = useMsal();
  const { classes } = useStyles();

  return (
    <Menu
      shadow={'lg'}
      transition={'pop'}
      position={'bottom-end'}
      styles={{
        item: {
          padding: '6px 12px',
        },
      }}>
      <Menu.Target>
        <div className={classes.menuItemWrapper}>
          <Group spacing={8} noWrap>
            <Stack p={0} spacing={0}>
              <Text
                size={'sm'}
                weight={'bold'}
                className={classes.truncate}
                color={theme.colors.gray[0]}>
                {instance.getActiveAccount()?.name}
              </Text>
            </Stack>
            <IconChevronDown size={18} color={theme.colors.gray[0]} />
          </Group>
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<IconLogout size={14} />} onClick={() => instance.logoutRedirect()}>
          <Text>Logga ut</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
