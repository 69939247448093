import { Stack, Title, TextInput, Container } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconSearch } from '@tabler/icons';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { FoundationsListViewColumns } from './FoundationsListViewColumns';
import { useDebouncedState } from '@mantine/hooks';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { Page } from '../../layout/Page';

export const FoundationsListView: React.FC = () => {
  const [searchResult, setSearchResult] = useState<any>();
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const api = useApi();
  //const [keyword, setKeyword] = useState<string | undefined>(undefined);
  const [keyword, setKeyword] = useDebouncedState('', 300);

  useEffect(() => {
    if (!keyword || keyword.length < 3) return;

    const fetchData = async () => {
      if (keyword) {
        setIsFetching(true);

        const result = await api.searchFoundations(keyword);
        setSearchResult(result);

        setIsFetching(false);
      }
    };

    fetchData();
  }, [keyword]);

  return (
    <Page>
      <Page.Header title="Sök stiftelser">
        <TextInput
          placeholder="Stiftelsens namn eller organisationsnummer"
          defaultValue={keyword}
          onChange={(event) => setKeyword(event.currentTarget.value)}
          // onKeyDown={(event) => {
          //   console.log('keyword', keyword);
          //   if (event.key === 'Enter') {
          //     setKeyword(keyword);
          //   }
          // }}
        />
        {searchResult && (
          <div>
            Hittade {searchResult.hits} resultat för <b>{keyword}</b>.
            {searchResult.hits >= searchResult.maxHits && (
              <> Visar de {searchResult.maxHits} första.</>
            )}
          </div>
        )}
      </Page.Header>
      <Page.Body>
        {/* </ViewContainer> */}
        <LiitGrid
          data={searchResult ? searchResult.foundations : []}
          columnInfo={FoundationsListViewColumns()}
          onRowClick={(id: string) => {
            navigate(`/foundations/${id}`);
          }}
          highligthOnHoover
          fetchingData={isFetching}
        />
      </Page.Body>
    </Page>
  );
};
