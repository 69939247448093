import { Container, DefaultProps, MantineNumberSize, Space, useMantineTheme } from '@mantine/core';
import React from 'react';
import { isPresent } from 'utilitype';

interface ViewContainerProps extends DefaultProps {
  children: React.ReactNode;
}

export const ViewContainer: React.FC<ViewContainerProps> = ({ children }) => {
  const theme = useMantineTheme();

  return (
    <Container
      w={'100%'}
      maw={'100%'}
      h="100%"
      ml={0}
      p={theme.spacing.xl}
      bg={theme.colors.gray[0]}>
      <Container ml={0} maw={800}>
        {children}
      </Container>
    </Container>
  );
};
